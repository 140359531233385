import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6221aa50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_ArrowLeft = _resolveComponent("ArrowLeft")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_menu, {
      class: "sidebar-el-menu",
      "default-active": _ctx.currentMenu,
      collapse: _ctx.isCollapse,
      "background-color": "#fff",
      "unique-opened": "",
      router: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.states.routersList, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (item.children.length!==0 && !item.hideChildrenInMenu)
              ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                  index: item.name,
                  key: index
                }, {
                  title: _withCtx(() => [
                    _createElementVNode("i", {
                      class: _normalizeClass(item.meta.icon)
                    }, null, 2),
                    _createElementVNode("span", null, _toDisplayString(item.meta.title), 1)
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (subItem, index2) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        (subItem.children.length!==0)
                          ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                              index: subItem.name,
                              key: index2
                            }, {
                              title: _withCtx(() => [
                                _createTextVNode(_toDisplayString(subItem.meta.title), 1)
                              ]),
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subItem.children, (threeItem, i) => {
                                  return (_openBlock(), _createBlock(_component_el_menu_item, {
                                    key: i,
                                    index: threeItem.name,
                                    onClick: ($event: any) => (_ctx.toView(threeItem.path, ''))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(threeItem.meta.title), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["index", "onClick"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["index"]))
                          : (!subItem.hidden)
                            ? (_openBlock(), _createBlock(_component_el_menu_item, {
                                index: subItem.name,
                                key: subItem.name,
                                onClick: ($event: any) => (_ctx.toView(subItem.path,subItem.redirect))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(subItem.meta.title), 1)
                                ]),
                                _: 2
                              }, 1032, ["index", "onClick"]))
                            : _createCommentVNode("", true)
                      ], 64))
                    }), 256))
                  ]),
                  _: 2
                }, 1032, ["index"]))
              : (_openBlock(), _createBlock(_component_el_menu_item, {
                  index: item.name,
                  key: index,
                  onClick: ($event: any) => (_ctx.toView(item.path,item.redirect))
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.meta.title), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("i", {
                      class: _normalizeClass(item.meta.icon)
                    }, null, 2)
                  ]),
                  _: 2
                }, 1032, ["index", "onClick"]))
          ], 64))
        }), 256))
      ]),
      _: 1
    }, 8, ["default-active", "collapse"]),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.switchCollapse && _ctx.switchCollapse(...args))),
      class: _normalizeClass({'open':!_ctx.isCollapse,'close':_ctx.isCollapse})
    }, [
      _createVNode(_component_el_icon, null, {
        default: _withCtx(() => [
          _createVNode(_component_ArrowLeft)
        ]),
        _: 1
      })
    ], 2)
  ]))
}