<template>
  <!-- 公共头部组件 -->
  <div class="header">
    <!-- 系统logo -->
    <div :class="{'logoOpen':!isCollapse,'logoClose':isCollapse}">
      <div class="logo-img">
        <img src="../../assets/logo.png"/>
      </div>
      <div v-show="!isCollapse" class="logo-title">云小二</div>
    </div>
    <!-- 头部组件右边功能区 -->
    <div class="header-right">
      <div class="header-user-con">
        <!-- 用户头像 -->
        <div class="user-avator">
          <!-- <img src="../../assets/img/login-bg.jpg" /> -->
          <span class="header-img">{{ user.name?.split('')[user.name.length - 1] }}</span>
        </div>
        <!-- 用户名下拉菜单：trigger属性配置 click 激活 command：点击菜单项触发的事件回调 -->
        <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <span style="margin-right: 8px;">{{ user.name }}</span>
          <el-icon><arrow-down /></el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="backHome" >
              <el-icon><School /></el-icon>
              返回工作台
            </el-dropdown-item>
            <el-dropdown-item @click="loginOut" >
              <el-icon><SwitchButton /></el-icon>
              退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { userDataStore } from '@/store'
import { ElMessage, ElMessageBox } from 'element-plus'

export default defineComponent({
  setup () {
    const router = useRouter()
    const store = userDataStore()
    const loginOut = () => {
      ElMessageBox.confirm('你确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        document.cookie = 'token=123;' + 'expires=Thu, 01 Jan 1970 00:00:00 GMT' + ';path=/;domain=' + process.env.VUE_APP_API_TOKEN
        window.location.href = `${process.env.VUE_APP_API_BASE_LOGIN}`
      }).catch(() => {
        ElMessage.info({
          message: '已取消'
        })
      })
    }
    const backHome = () => {
      window.location.href = process.env.VUE_APP_API_BASE_CONSOLE || ''
    }
    // 获取 DOM 元素
    const domTopRef: any = ref(null)
    const users = computed(() => store.userList)
    const userInfo = JSON.parse(JSON.stringify(users.value))
    const isCollapse = computed(() => store.getIsCollapse)
    const switchCollapse = () => {
      store.switchCollapse(!isCollapse.value)
    }

    const user = reactive({
      name: userInfo.userName,
      photoUrl: 'https://img0.baidu.com/it/u=2924490029,2419431244&fm=253&fmt=auto&app=138&f=JPEG?w=200&h=200'
    })

    // 获取按钮权限
    // getUserInfoApi().then((res: any) => {
    //   if (res.data.code !== 200) return ElMessage.error(res.data.message)
    //   user.name = res.data.data.userName
    // })

    return {
      user,
      backHome,
      loginOut,
      domTopRef,
      isCollapse,
      switchCollapse
    }
  }
})
</script>

<style  scoped>
 .header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    font-size: 22px;
    border-bottom: 1px solid #f3f3f3;
    background-color: #ffffff;
    /* color: #fff; */
  }
  .collapse-btn {
    float: left;
    padding: 0 21px;
    cursor: pointer;
    line-height: 56px;
  }
  .logoOpen,.logoClose {
    cursor: pointer;
    float: left;
    width: 208px;
    height: 56px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #f3f3f3;
  }
  .note-layout{
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .note-layout .note-icon{
      font-size:20px;
      margin-right:18px;
    cursor:pointer;
    position:relative;
  }
  .note-layout .message-icon{
      font-size:20px;
      margin-right:18px;
      cursor:pointer;
  }
  .note-layout .note-icon::after{
    content:' ';
    position:absolute;
    right:1px;
    top:2px;
    width:7px;
    height:7px;
    background-color:#f5222d !important;
    border-radius:50%;
  }
  .logoClose {
    width: 64px;
  }
  .logo-img{
    background: #0089FF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
  .logo-img img{
    width: 28px;
    height: 28px;
  }
  .logo-title{
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin-left: 8px;
    line-height: 2;
  }
  .header-right {
    float: right;
    padding-right: 24px;
  }
  .header-user-con {
    display: flex;
    height: 56px;
    align-items: center;
  }
  .skin{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .skin span {
    font-size: 19px
  }
  .btn-fullscreen {
    transform: rotate(45deg);
    margin-left: 16px;
    margin-right: 5px;
    font-size: 24px;
  }
  .btn-bell,
  .btn-fullscreen {
    position: relative;
    width: 30px;
    height: 25px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
  }
  .btn-bell-badge {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    /* color: #fff; */
  }
  .btn-bell .el-icon-bell {
    color: #333;
  }
  .user-name {
    margin-left: 10px;
  }
  .user-avator {
    margin-left: 20px;
  }
  .user-avator img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .header-img{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 100%;
    color: #fff;
    background: #0089FF;
    font-size: 14px;
    font-weight: 400;
  }
  .el-dropdown-link {
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .el-dropdown-menu__item {
    text-align: center;
  }
  .play-icon{
    font-size: 22px;
    margin-right:15px;
    position: relative;top:1px;
    cursor:pointer
  }
</style>
